import { forwardRef } from "react";
import {
  type LandmarkLinkVariants,
  landmarkLinkVariants,
} from "./landmark-link.styles";

export type LandmarkLinkRef = React.ElementRef<"a">;

export interface LandmarkLinkProps
  extends Omit<React.ComponentPropsWithoutRef<"a">, "children">,
    LandmarkLinkVariants {
  label: string;
  slug: string;
  type: "trigger" | "target";
}

const LandmarkLink = forwardRef<LandmarkLinkRef, LandmarkLinkProps>(
  ({ className, label, slug, type, ...props }, ref) => {
    return (
      <a
        {...props}
        id={type === "trigger" ? `${slug}-trigger` : slug}
        href={`#${slug}`}
        className={landmarkLinkVariants({ className })}
        ref={ref}
      >
        {`${type === "trigger" ? "Skip to" : "Start of"} ${label}`}
      </a>
    );
  },
);

LandmarkLink.displayName = "LandmarkLink";

export { LandmarkLink };
