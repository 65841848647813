import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  type DialogProps,
  DialogTitle,
  DialogTrigger,
  type DialogTriggerProps,
  type DialogTriggerRef,
} from "#ui/dialog";
import { forwardRef } from "react";

export type CookieSettingsDialogRef = DialogTriggerRef;

export interface CookieSettingsDialogProps extends DialogTriggerProps {
  rootProps?: DialogProps;
}

const CookieSettingsDialog = forwardRef<
  CookieSettingsDialogRef,
  CookieSettingsDialogProps
>(({ className, rootProps, ...props }, ref) => {
  return (
    <Dialog {...rootProps}>
      <DialogTrigger {...props} ref={ref} />
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          aria-describedby={undefined}
          variant="center"
          className="w-800px max-w-full"
        >
          <DialogTitle className="sr-only">Cookie Settings</DialogTitle>
          <div>TODO: Cookie Settings</div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
});

CookieSettingsDialog.displayName = "CookieSettingsDialog";

export { CookieSettingsDialog };
