import { buttonBaseClassNames, focusOutlineClassNames } from "./button.styles";
import { cva, type VariantProps } from "./style.utils";

export type LandmarkLinkVariants = VariantProps<typeof landmarkLinkVariants>;

export const landmarkLinkVariants = cva({
  base: [
    buttonBaseClassNames,
    // positioning
    "fixed -top-36px left-15px z-50",
    // display
    "h-36px gap-5px px-20px",
    // borders
    "border-1.5",
    // background/foreground
    "bg-secondary-120 text-white",
    // animation
    "transition-all",
    // focus unset/reset
    focusOutlineClassNames,
    // focus positioning
    "focus-visible:top-14px",
  ],
});
